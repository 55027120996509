body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select,
img,
div {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

img,
fieldset {
  border: 0;
}

ul,
ol {
  list-style: none;
}

em,
address {
  font-style: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  table-layout: fixed;
}

caption {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
}

button {
  cursor: pointer;
  outline: 0;
  border: 0;
  background: none;
  vertical-align: middle;
}

body,
input,
select,
textarea {
  outline: none;
}
